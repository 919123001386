.sidebarStyle {
	margin: 12px;
	background-color: #8d8b8b;
	color: #ffffff;
	z-index: 1 !important;
	padding: 6px;
	font-weight: bold;
}
.overlayStyle {
	position: absolute;
}
