@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.del-btn {
	margin-left: 40px;
}

.car-images {
	margin-top: 20px;
}

.car-images h4 {
	margin: 20px 0;
}

.car-images-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
}

.car-images-container img {
	margin-right: 20px;
}

.sidebarStyle {
	margin: 12px;
	background-color: #8d8b8b;
	color: #ffffff;
	z-index: 1 !important;
	padding: 6px;
	font-weight: bold;
}
.overlayStyle {
	position: absolute;
}

.rt-th {
	display: -webkit-flex;
	display: flex;
}

.rt-tr-group {
	cursor: pointer !important;
}

h3 {
	color: black;
	padding: 20px 0;
}

body {
	background-color: #f9fbfc;
}

.container-promos {
	/* height: 100vh; */
	overflow: scroll;
	height: 100%;
}

